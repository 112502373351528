/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import classNames from 'classnames';
import getConfig from 'next/config';
import { connect } from 'react-redux';

import ErrorMetadata from 'components/PageMetadata/ErrorMetadata';
import ErrorMessage from 'components/ErrorMessage';
import { HeaderAndFooter } from 'components/services/HeaderAndFooter';
import { AnalyticsLaunchScripts } from 'components/AnalyticsLaunchScripts';
import IconfontStyleTag from 'components/IconfontStyleTag';
import { ThemesCssBundle } from 'components/ThemesCssBundle';
import { navbar, NAVBAR_THEME } from 'lib/navbar';
import { layout as servicesLayoutPropType } from 'lib/CustomPropTypes/services';
import globalContainerStyles from './globalContainerStyles.module.scss';

const { publicRuntimeConfig: envVars } = getConfig();

const ENABLE_ERROR_PAGE_PREVIEW = envVars.ENABLE_ERROR_PAGE_PREVIEW === 'true';

const PAGE_TYPES = {
  403: 'Forbidden',
  404: 'NotFound',
  500: 'InternalServerError',
};

const mapStateToProps = ({
  shared,
}) => ({
  vertical: shared.vertical,
  isChromeless: shared.isChromeless,
});

export const navbarConfig = {
  theme: ({ vertical }) => {
    switch (vertical) {
      case 'telemundo':
      case 'deportes':
      case 'localstationdeportes':
      case 'noticias':
      case 'entretenimiento':
      case 'shows':
      case 'news':
      case 'select':
      case 'msnbc':
        return NAVBAR_THEME.VERTICAL;
      default:
        return NAVBAR_THEME.LIGHT;
    }
  },
};

// ensures only supported status codes are used
export const validatedStatusCode = (statusCode) => {
  if (Object.keys(PAGE_TYPES).includes(`${statusCode}`)) {
    return statusCode;
  }

  return 500;
};

function ErrorPage({
  statusCode, vertical, layout, isChromeless,
}) {
  const Error = () => (
    <>
      <Head>
        <IconfontStyleTag />
      </Head>
      <ThemesCssBundle vertical={vertical} />

      <div>
        <ErrorMetadata pageType={PAGE_TYPES[statusCode] || PAGE_TYPES[500]} />
        <ErrorMessage code={statusCode} vertical={vertical} />
      </div>
    </>
  );

  const renderHeaderAndFooter = !isChromeless && Object.keys(layout ?? {}).length > 0;

  const renderErrorPage = (Component) => {
    if (renderHeaderAndFooter) {
      return (
        <HeaderAndFooter layout={layout} vertical={vertical}>
          <Component />
        </HeaderAndFooter>
      );
    }

    return <Component />;
  };

  return (
    <div className={classNames(globalContainerStyles.container)} data-testid="error-page">
      {renderErrorPage(Error)}
      <AnalyticsLaunchScripts />
    </div>
  );
}

ErrorPage.propTypes = {
  isChromeless: PropTypes.bool.isRequired,
  statusCode: PropTypes.number.isRequired,
  vertical: PropTypes.string.isRequired,
  layout: servicesLayoutPropType.isRequired,
};

ErrorPage.getInitialProps = async ({
  req, res, err,
}) => {
  let previewStatusCode;
  if (ENABLE_ERROR_PAGE_PREVIEW) {
    previewStatusCode = req?.params?.statusCode && parseInt(req?.params?.statusCode, 10);
  }

  const resStatusCode = previewStatusCode || res?.statusCode;
  const errStatusCode = err?.statusCode;
  const { layout } = res.locals;

  return {
    statusCode: validatedStatusCode(resStatusCode || errStatusCode),
    layout,
  };
};

export default navbar(navbarConfig)(
  connect(mapStateToProps)(ErrorPage),
);

export { ErrorPage as UnwrappedErrorPage };
