import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  activate as activateAction,
  deactivate as deactivateAction,
  setHeadline as setHeadlineAction,
  setShortcutVisible as setShortcutsVisibleAction,
  setTheme as setThemeAction,
  setDuration as setDurationAction,
} from 'redux/modules/navbar';

import BTE from 'lib/BTE';

export const NAVBAR_THEME = {
  TRANSPARENT: 'transparent',
  TRANSPARENT_LIGHT: 'transparentLight',
  LIGHT: 'light',
  DARK: 'dark',
  VERTICAL: 'vertical',
};

export function navbar(configuration) {
  // navbar() configuration options
  const {
    // ScrollY position to trigger the 'active' menu class
    // @param {integer|function}
    activeAt: getActiveAt,

    // Number in seconds for overriding the local animations duration
    setDuration: localAnimationDuration,

    // Function that determines content headline
    headline: getHeadline,

    // Disable or enable the navigation shortcuts. This is enabled by default
    // Function that determines shortcuts value
    showShortcuts: getShowShortcuts,

    // Function that determines theme value
    theme: getTheme,
  } = configuration;

  return function navbarWrapper(Page) {
    class Navbar extends React.Component {
      static WrappedComponent = Page;

      static propTypes = {
        children: PropTypes.oneOfType([
          PropTypes.arrayOf(PropTypes.node),
          PropTypes.node,
        ]),
        navbarContent: PropTypes.shape({}).isRequired,
        navbarActive: PropTypes.bool,
        navbarActivate: PropTypes.func,
        navbarDeactivate: PropTypes.func,
        navbarSetHeadline: PropTypes.func,
        navbarSetShortcutVisible: PropTypes.func,
        navbarSetTheme: PropTypes.func,
        navbarSetDuration: PropTypes.func,
        path: PropTypes.string,
        pageView: PropTypes.string,
        section: PropTypes.string,
        vertical: PropTypes.string,
      };

      static defaultProps = {
        children: null,
        navbarActive: false,
        navbarActivate: Function.prototype,
        navbarDeactivate: Function.prototype,
        navbarSetHeadline: Function.prototype,
        navbarSetShortcutVisible: Function.prototype,
        navbarSetTheme: Function.prototype,
        navbarSetDuration: null,
        path: null,
        pageView: null,
        section: null,
        vertical: null,
      };

      constructor(props) {
        super(props);

        this.setHeadline();
        this.setShowShortcuts();
        this.setTheme();
        this.setDuration();
      }

      componentDidMount() {
        const { vertical, navbarContent } = this.props;

        if (getActiveAt) {
          this.activeAt = (typeof getActiveAt === 'function')
            ? getActiveAt({ vertical, navbarContent })
            : getActiveAt;

          if (this.activeAt) {
            BTE.on('scroll', this.monitorScroll, 'throttle');
          }
        }
      }

      shouldComponentUpdate() {
        return false;
      }

      componentWillUnmount() {
        if (this.activeAt) {
          BTE.remove('scroll', this.monitorScroll, 'throttle');
        }
      }

      monitorScroll = (depth) => {
        const {
          navbarActive,
          navbarActivate,
          navbarDeactivate,
        } = this.props;

        if (depth > this.activeAt && !navbarActive) {
          navbarActivate();
        } else if (depth < this.activeAt && navbarActive) {
          navbarDeactivate();
        }
      }

      setHeadline = () => {
        if (getHeadline) {
          const {
            navbarContent,
            navbarSetHeadline,
          } = this.props;

          const headline = getHeadline({
            content: navbarContent,
          });
          navbarSetHeadline(headline);
        }
      }

      setShowShortcuts = () => {
        if (getShowShortcuts) {
          const {
            navbarContent,
            navbarSetShortcutVisible,
          } = this.props;

          const shortcuts = getShowShortcuts({
            content: navbarContent,
          });

          navbarSetShortcutVisible(shortcuts);
        }
      }

      setDuration = () => {
        if (localAnimationDuration > -1) {
          const { navbarSetDuration } = this.props;
          navbarSetDuration(localAnimationDuration);
        }
      }

      setTheme = () => {
        if (getTheme) {
          const {
            navbarContent,
            navbarSetTheme,
            path,
            section,
            vertical,
          } = this.props;

          const theme = getTheme({
            content: navbarContent,
            path,
            section,
            vertical,
          });
          navbarSetTheme(theme);
        }
      }

      render() {
        const {
          children, ...props
        } = this.props;

        const childProps = Object.keys(this.props)
          .filter((key) => key.search(/^navbar[A-Z]/) === -1)
          .reduce((obj, key) => {
            // eslint-disable-next-line no-param-reassign
            obj[key] = props?.[key];
            return obj;
          }, {});

        return (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Page data-test="nav-bar-page" {...childProps}>
            {children}
          </Page>
        );
      }
    }

    const mapStateToProps = (storeProps, ownProps) => {
      const {
        navbar: { active },
        article,
        front,
        recipe,
        slideshow,
        video,
      } = storeProps;

      const {
        pageView,
        path,
        section,
        vertical,
      } = ownProps;

      let navbarContent = {};
      switch (pageView) {
        case 'article':
        case 'showBlog':
          navbarContent = article?.content?.[0] ?? {};
          break;
        case 'front':
          navbarContent = front;
          break;
        case 'recipe':
          navbarContent = recipe?.current ?? {};
          break;
        case 'slideshow':
          navbarContent = slideshow?.current ?? {};
          break;
        case 'video':
          navbarContent = video;
          break;
        default:
          navbarContent = {};
      }

      return {
        // store props
        navbarActive: active,
        navbarContent,
        // own props
        pageView,
        path,
        section,
        vertical,
      };
    };

    const mapActionsToProps = (dispatch) => ({
      navbarActivate: () => dispatch(activateAction()),
      navbarDeactivate: () => dispatch(deactivateAction()),
      navbarSetShortcutVisible: (payload) => dispatch(setShortcutsVisibleAction(payload)),
      navbarSetHeadline: (payload) => dispatch(setHeadlineAction(payload)),
      navbarSetTheme: (payload) => dispatch(setThemeAction(payload)),
      navbarSetDuration: (payload) => dispatch(setDurationAction(payload)),
    });

    return connect(mapStateToProps, mapActionsToProps)(
      Navbar,
    );
  };
}
